import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import FilterButton from 'components/Global/Filter/FilterButton';
import DrugCategories from 'components/DrugCategories';
import MyModal from 'components/Global/MyModal';
import DrugFilterBody from 'components/DrugFilterBody';
import HeaderWithoutLogo from 'sections/HeaderWithoutLogo';

const  DrugCategory = (props) => {
    const [showFilterModal, setShowFilterModal] = useState(false);
    const [selectedConnections, setSelectedConnections] = useState({});   
    let {slug} = useParams();

    const handleFilterModal = () => {
        setShowFilterModal(! showFilterModal);
    }

    const handleSelectedConnections = (fromChildSelectedConnections) => {
        setSelectedConnections(fromChildSelectedConnections);
        setShowFilterModal(false);
    }

    let filterBody = <DrugFilterBody setParentConnections={handleSelectedConnections} />;
    return (
        <>
        <HeaderWithoutLogo title='Դասակարգել'/>
            <FilterButton onClick={() => handleFilterModal()}  />
            <DrugCategories title={`Ընտրել խմբավորումը`} defaultSlug={slug} selectedConnections={selectedConnections}/>
            <MyModal
                show={showFilterModal}
                onHide={() => setShowFilterModal(false)}
                body={filterBody}
                centered
            />
        </>
    );
}

export default DrugCategory;
