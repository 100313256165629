import React from 'react';
import PropTypes from 'prop-types';

import Doctors from 'pages/Doctors';

import './component.css';
import { Link } from 'react-router-dom';
const BaseDomain = `${process.env.REACT_APP_BASE_DOMAIN}`;

const WorkplaceWithDoctors = ({ workplace, departmentSlug }) => {
  let workplaceLink =  departmentSlug ? `/department/${departmentSlug}/${workplace.slug}` : `/workplace/one/${workplace.slug}`;
  return (
    <>
   
    <Link to={workplaceLink}>
      <div className="row clinicRow">
          <div className="col-12">
              <img src={`${BaseDomain + workplace.imagePath}`} className="logo" alt="workplace"/>
          </div>
          <div className="col-12">
              <h2 className="title">{workplace.titleArm}</h2>
          </div>
      </div>
      </Link>
      <Doctors doctors={workplace.doctors} />
    </>
  );
}

WorkplaceWithDoctors.propTypes = {
  workplace: PropTypes.object,
  departmentSlug: PropTypes.string
};

export default WorkplaceWithDoctors;
