import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';

import Layout from 'layout';
import Loading from 'services/loading';
import { makeid, setCookie, getCookie } from 'utils';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

const App = () => {
  const key = 'ITCSessId';
  const value = makeid(30);
  const expirationDays = 60;
  const existingValue = getCookie(key);

  useEffect(() => {
    if (!existingValue) {
      setCookie(key, value, expirationDays);
    }
  }, [value, existingValue]);

  return (
    <Container className='mainContainer pt-7'>
      <Loading />
      <Layout />
    </Container>
  );
}

export default App;
