import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { getPharmsWithDrugs } from 'services/api';

import Drugs from 'components/Drugs';
import DrugFilterBody from 'components/DrugFilterBody';
import MyModal from 'components/Global/MyModal';
import FilterButton from 'components/Global/Filter/FilterButton';
import HeaderWithoutLogo from 'sections/HeaderWithoutLogo';

const PharmsWithDrugsByCategory = () => {
  const { slug } = useParams();
  const [ pharms, setPharms ] = useState([]);
  const [ category, setCategory ] = useState([]);
  const [ showFilterModal, setShowFilterModal ] = useState(false);

  const renderPharmsWithDrugs = () => {
    return pharms && pharms.length && pharms.map(pharm =>
      <Col xs={12} key={pharm.id}>
        <h3 className="departmentWithDoctorsTitle">
          {pharm.titleArm}
        </h3>
        <Drugs drugs={pharm.drugs}/>
      </Col>
    );
  };

  const handleFilterModal = () => {
    setShowFilterModal(!showFilterModal);
  }

  useEffect(() => {
    if (!category.length || !pharms.length) {
      getPharmsWithDrugs(slug)
      .then(res => {
        setPharms(res.pharms);
        setCategory(res.category);
      })
      .catch(e => {
        console.log(e);
      });
    }
    return () => {
      setPharms([]);
      setCategory([]);
    }
  }, []);

  return (
    <>
      <HeaderWithoutLogo title={category ? category : ''}/>
      <FilterButton onClick={() => handleFilterModal()}/>
      {renderPharmsWithDrugs()}
      <MyModal
        centered
        body={<DrugFilterBody />}
        show={showFilterModal}
        onHide={() => setShowFilterModal(false)}
      />
    </>
  );
}

export default PharmsWithDrugsByCategory;
