import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';


import plus from 'assets/Application/plus.svg';
import arrow from 'assets/SearchBar/Vector.png';

import './component.css';


const CreateService = ({
  icon,
  name,
  action,
  withoutNew,
  backWithAction
}) => {
  const navigate = useNavigate();

  const handleCreateService = () => {
    action && action();
  };

  const handleBackClick = () => {
    if (backWithAction) {
      backWithAction();
    } else {
      navigate(-1);
    }
  };

  return (
    <div className='create-service-block'>
      <div
        className='create-service-item'
        style={{
          justifyContent: withoutNew ? 'flex-start' : 'space-between'
        }}
      >
        <div className='create-service-back-block'>
            <img onClick={handleBackClick} src={arrow} className='slack' alt='back'/>
        </div>
        <div className='create-service-icon-block'>
          <img src={icon} alt='service' />
        </div>

        <div className='create-service-text-block'>
          <p className='create-service-text body2-300-title'>
            { name }
          </p>
        </div>

        {
          !withoutNew && <div
            className='create-service-plus-block'
            onClick={handleCreateService}
          >
            <img src={plus} alt='plus' />
          </div>
        }

      </div>
    </div>
  )
}

CreateService.propTypes = {
  action: PropTypes.func,
  withoutNew: PropTypes.bool,
  backWithAction: PropTypes.func,
  icon: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default CreateService
