import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import { getPharm } from 'services/api';

import DrugFilterBody from 'components/DrugFilterBody';
import MyModal from 'components/Global/MyModal';
import DrugCategories from 'components/DrugCategories';
import FilterButton from 'components/Global/Filter/FilterButton';
import WorkplaceSocialBlock from 'components/WorkplaceSocialBlock';
import HeaderWithoutLogo from 'sections/HeaderWithoutLogo';
import WorkplaceHeaderInfo from 'components/WorkplaceHeaderInfo';

import PhoneIcon from 'assets/Doctors_Social_Block/phone.png';
import arrow from 'assets/DoctorPage/DoctorsSinglePage/arrow.png';
import LocationIcon from 'assets/DoctorPage/DoctorsSinglePage/locationIcon.png';

const OnePharm = () => {
  const { pharmSlug } = useParams();
  const [ pharm, setPharm ] = useState(null);   
  const [ serviceView, setServiceView ] = useState('drugs');
  const [ showFilterModal, setShowFilterModal ] = useState(false);
  const [ workplacesHeightClass, setWorkplacesHeightClass ] = useState(true);

  const setOrRemoveWorkplaceClass = (param) => {
    setWorkplacesHeightClass(param);
  }

  const renderWorkplaces = () => {
    if (pharm && pharm.children.length > 0) {
      return pharm.children.map(oneWorkplace =>
        <div key={oneWorkplace.id} className='mb-4'>
          <div className={``}>
            <p className='f-s-12 fw-500'>
              <img src={LocationIcon} className='locationIcon' alt='location'/>
              {oneWorkplace.titleArm}
            </p>
            <p className='f-s-12 mt8'>
              <img src={PhoneIcon} className='addressPhoneIcon' alt='phone'/>
              {oneWorkplace.phone}
            </p>
          </div>
        </div>
      );
    }
  }

  const changeService = (service) => {
    setServiceView(service);
  }

  const handleFilterModal = () => {
    setShowFilterModal(! showFilterModal);
  }

  const filterBody = <DrugFilterBody/>;

  useEffect(() => {
    if (!pharm) {
      getPharm(pharmSlug)
      .then(res => {
        setPharm(res);
      })
      .catch(e => {
        console.log(e);
      });
    }
    return () => {
      setPharm(null);
    }
  }, []);

  return (
    <>
      {
        pharm && <>
          <HeaderWithoutLogo title={pharm.pharmCategory.titleArm}/>
          <WorkplaceHeaderInfo workplace={pharm} withAddress={false}/>
          <Col xs={12} className='mt16 workplacesDiv'>
            <h2 className='f-s-16 text-black fw-bold mb-4'>{pharm.titleArm}</h2>
            <Row>
              <Col xs={10} className={`${workplacesHeightClass ? 'h56' : ''}`}>
                {renderWorkplaces()}
              </Col>
              <Col xs={2}>
                <img
                  src={arrow}
                  alt='arrow'
                  className='arrow'
                  onClick={() => setOrRemoveWorkplaceClass(!workplacesHeightClass)}
                />
              </Col>
            </Row>
          </Col>
          <WorkplaceSocialBlock workplace={pharm}/>
          <ul className='workplaceMenu'>
            <li
              onClick={() => changeService('drugs')}
              className={serviceView === 'drugs' ? 'active' : ''}
            >
              Դեղորայք
            </li>
              
            {
              pharm.children.length > 0 && <li 
                onClick={() => changeService('children')}
                className={serviceView === 'children' ? 'active' : ''} 
              >
                Մասնաճյուղեր
              </li>
            }
          </ul>
        
          {
            serviceView === 'drugs' && <>
              <FilterButton onClick={() => handleFilterModal()} />
              <DrugCategories title={`Ընտրել խմբավորումը`}/>
            </>
          }
          <MyModal
            centered
            body={filterBody}
            show={showFilterModal}
            onHide={() => setShowFilterModal(false)}
          />
        </>
      }
    </>
  );
}

export default OnePharm;
