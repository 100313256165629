import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import {
  addDiagnosis,
  addDiagnosisTemplates,
  getDoctorProfileDiagnosis
} from 'services/api';

import { resetAddMedCardOrDoctor } from 'features/addMedCardOrDoctor';
import { resetDiagnosis } from 'features/diagnosis';

import Button from 'components/Button';
import NestedList from 'components/NestedList';
import FilterTabs from 'components/FilterTabs';
import FilterSearch from 'components/FilterSearch';
import CreateService from 'components/CreateService';

import pdf from 'assets/DoctorPage/pdf.svg';
import backArrow from 'assets/Diagnos/back-arrow.svg';

import './component.css';
import DiagnosListCard from 'components/DiagnosListCard';

const Diagnosis = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { items } = useSelector(state => state.diagnosis);
  const { item } = useSelector(state => state.addDiagnosis);
  const { savedItems } = useSelector(state => state.diagnosis);
  const doctorOrMedcard = useSelector(state => state.addMedCardOrDoctor.item);

  const [ checkBack, setCheckBack ] = useState([]);
  const [ filterItem, setFilterItem ] = useState('');
  const [ diagnosisIds, setDiagnosisIds ] = useState([]);
  const [ allDiagnosis, setAllDiagnosis ] = useState([]);
  const [ tempChildren, setTempChildren ] = useState([]);
  const [ activeFilter, setActiveFilter ] = useState('Ախտորոշումներ');
  

  const handleSetActive = (active) => {
    setActiveFilter(_ => active);
  };

  const handleFilter = (searchText) => {
    setFilterItem(_ => searchText);
  };

  const handleGetDiagnosId = (type, id, slug) => {
    if (type === 'single') {
      let idsArr = diagnosisIds;
      if (!idsArr.length) {
        setDiagnosisIds(_ => {
          return [
            id
          ]
        });
      } else if (!idsArr.includes(id)) {
        idsArr = [...diagnosisIds, id];
        setDiagnosisIds(_ => {
          return [
            ...idsArr
          ]
        });
      } else {
        const index = idsArr.indexOf(id);
        idsArr.splice(index, 1);
        setDiagnosisIds(_ => {
          return [
            ...idsArr
          ]
        });
      }
    } else {
      getDoctorProfileDiagnosis(dispatch, slug);
      if (!checkBack.length) {
        setCheckBack(() => {
          return [
            slug
          ]
        })
      } else if (!checkBack.includes(slug)) {
        const slugArr = [...checkBack, slug];
        setCheckBack(() => {
          return [
            ...slugArr
          ]
        });
      } else {
        const slugArr = [...checkBack];
        const index = slugArr.indexOf(slug);
        slugArr.splice(index, 1);
        setCheckBack(() => {
          return [
            ...slugArr
          ]
        });
      }
      // const item = !tempChildren.length ? allDiagnosis.filter(item => item.id === id) : tempChildren[tempChildren.length - 1].filter(item => item.id === id);
      // const { children } = item[0];
      // console.log(children, 'children - - - - ->>>')
      // setTempChildren(prev => {
      //   return [
      //     ...prev,
      //     children
      //   ]
      // });
    }
  };

  const handleAddDiagnosis = () => {
    const params = { ...item };
    params.diagnosis = diagnosisIds;
    if (doctorOrMedcard === 'medCard') {
      addDiagnosis(dispatch, params)
      .then(() => {
        navigate('/oneMedCard')
      })
      .catch(e => {
        console.log(e);
      });
    } else if (doctorOrMedcard === 'doctor') {
      addDiagnosisTemplates(dispatch, params)
      .then(() => {
        navigate('/doctorAccount')
      })
      .catch(e => {
        console.log(e);
      });
    }
    
  };

  const handleBack = () => {
    let updatedChildren = tempChildren;
    updatedChildren.pop();
    setTempChildren(_ => {
      return [
        ...updatedChildren
      ]
    });
  };

  const backWithAction = () => {
    if (checkBack.length) {
      const removeLastCheckBack = [...checkBack];
      if (removeLastCheckBack.length === 1) {
        getDoctorProfileDiagnosis(dispatch);
      } else {
        const lastFirstSlug = removeLastCheckBack[removeLastCheckBack.length - 2];
        getDoctorProfileDiagnosis(dispatch, lastFirstSlug);
      }
      removeLastCheckBack.pop();
      setCheckBack(() => {
        return [
          ...removeLastCheckBack
        ]
      });
    } else {
      dispatch(resetDiagnosis())
      navigate('/doctorAccount');
    }
  }

  useEffect(() => {
    if (!items.length) {
      getDoctorProfileDiagnosis(dispatch);
    }

    // if (doctorOrMedcard === '') {
    //   navigate(-1);
    // }

    //  TODO:
    // return () => {
    //   doctorOrMedcard !== '' && dispatch(resetAddMedCardOrDoctor());
    // }
  }, []);

  useEffect(() => {
    setAllDiagnosis(_ => {
      return [
        ...items
      ]
    });
  }, [items]);

  return (
    <div className='diagnosis-list-parent-block'>
      <div
        className='diagnosis-fix-btn-block'
        style={{
          display: diagnosisIds.length  ? 'block' : 'none'
        }}
      >
        <Button
          bgColor
          text='Պահպանել'
          click={handleAddDiagnosis}
        />
      </div>

      <CreateService
        icon={pdf}
        withoutNew
        backWithAction={backWithAction}
        name='Ուղեցույց/Ախտորոշումներ'
      />

      <FilterTabs
        tabs={['Ախտորոշումներ', 'Պահպանված']}
        checkActive={handleSetActive}
      />

      <FilterSearch
        filterCall={handleFilter}
      />

      <div className='diagnosis-list-block'>
        <div
          className='diagnosis-list-block-back-btn-block'
          style={{
            display: tempChildren.length > 0 ? 'block' : 'none'
          }}
          onClick={handleBack}
        >
          <img src={backArrow} alt='back' className='diagnosis-list-block-back-icon' />
        </div>
        {
          activeFilter === 'Ախտորոշումներ' ? !tempChildren.length > 0 ?
            <NestedList
              ids={diagnosisIds}
              handleGetDiagnosId={handleGetDiagnosId}
              data={
                filterItem === '' ?
                allDiagnosis :
                allDiagnosis.filter(item => item.titleArm.includes(filterItem) || item.code.includes(filterItem))
              }
            /> :
            <NestedList
              ids={diagnosisIds}
              handleGetDiagnosId={handleGetDiagnosId}
              data={
                filterItem === '' ?
                tempChildren[tempChildren.length - 1] :
                tempChildren[tempChildren.length - 1].filter(item => item.titleArm.includes(filterItem) || item.code.includes(filterItem))
              }
            />
            :
            <>
            {
              //todo ճշտել ճիշտ լոգիկան ու վերջնականի բերել
              savedItems.length > 0 && savedItems.map((item, index) => {
                return <DiagnosListCard
                  ids={[]}
                  item={item}
                  key={`${item.slug}_${index}`}
                  checkCall={handleGetDiagnosId}
                  withShareActions
                />
              })
            }
          </>
        }
      </div>
    </div>
  )
};

export default Diagnosis
