import React from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { join } from 'utils';
import TitleWithBorder from '../Global/titleWithBorder';


let a = [
    { day: 'numeric' },
    { month: 'numeric' },
    { year: 'numeric' }
  ];

const CreateCallForm = ({
    doctor,
    formData,
    onSubmit,
    onCancel,
    handleChangeForm,
    errors = []
}) => {
    return (
        <div>
            {doctor && 
                <>
                    <h3 className='text-center f-s-20 mb24 fw-bold'>Հերթագրվել</h3>
                    <h4 className='f-s-14 fw-bold'>{doctor.workplaces && doctor.workplaces[0].workplace.titleArm}</h4>
                    <div className='servicesList'>
                        <p className='f-s-14 serviceBlockTitle'>Ծառայություններ</p>
                        <p className='mt-2 f-s-12'>{doctor.services && doctor.services[0].service.titleArm}
                            <span className='float-right servicePrice'>{doctor.services && doctor.services[0].price}դր</span>
                        </p>
                        <p className='serviceTotalDiv f-s-14 fw-bold totalTitle'>
                            Ընդամենը <span className='float-right'>{doctor.services && doctor.services[0].price}դր</span>
                        </p>
                    </div>
                    <div className='dateAndTimeBlock row f-s-12'>
                        <p className='mb22'>Ընտրեք Օր և Ժամ *</p>
                        <Col className='text-center'><p>{formData.date}</p></Col>
                        <Col className='text-center'><p>{formData.time}</p></Col>
                    </div>
                </>
            }

           
            <div className='paymentBlock row'>
                <p className='f-s-12 mb-2'>Վճարման Եղանակը</p>

                <TitleWithBorder title={`Կանխիկ`} colClass='col-6' classes='col-pd-r-7 mb16 activeTitle' />
                <TitleWithBorder title={`Pos Տերմինալ`} colClass='col-6' classes='col-pd-l-7 mb16 disabledTitle' />
                <TitleWithBorder title={`Քարտային`} colClass='col-6' classes='col-pd-r-7 mb16 disabledTitle' />
                <TitleWithBorder title={`Օնլայն դրամապանակ`} colClass='col-6' classes='col-pd-l-7 mb16 disabledTitle' />
            </div>
            <div className='detailsBlock'>
                <Form.Group className='mb-3'>
                    <Form.Control
                        onChange={(e) => handleChangeForm('', '', e)}
                        name='firstName'
                        type='text'
                        placeholder='Անուն'
                        className={errors && errors.firstName && 'is-invalid'}
                        />
                        {
                        errors.firstName && <Form.Control.Feedback type='invalid'>
                            {errors.firstName}
                    </Form.Control.Feedback>
                    }
                </Form.Group>
                <Form.Group className='mb-3'>
                    <Form.Control
                        type='text'
                        name='lastName'
                        placeholder='Ազգանուն'
                        onChange={(e) => handleChangeForm('', '', e)}
                        className={errors && errors.lastName && 'is-invalid'}
                    />
                    {
                    errors.lastName && <Form.Control.Feedback type='invalid'>
                        {errors.lastName}
                    </Form.Control.Feedback>
                    }
                </Form.Group>
                <Form.Group className='mb-3'>
                    <Form.Control
                        name='email'
                        type='email'
                        placeholder='Էլ․ հասցե'
                        onChange={(e) => handleChangeForm('', '', e)}
                        className={errors && errors.email && 'is-invalid'}
                    />
                    {
                    errors.email && <Form.Control.Feedback type='invalid'>
                        {errors.email}
                    </Form.Control.Feedback>
                    }
                </Form.Group>
                <Form.Group className='mb-3'>
                    <Form.Control
                        name='phone'
                        type='text'
                        onChange={(e) => handleChangeForm('', '', e)}
                        placeholder='Հեռախոսահամար +374 77000000'
                        className={errors && errors.phone && 'is-invalid'}
                    />
                    {
                    errors.phone && <Form.Control.Feedback type='invalid'>
                        {errors.phone}
                    </Form.Control.Feedback>
                    }
                </Form.Group>
            </div>
            <Row className='buttonsDiv pb16'>
                <Col xs={6} className='text-center'>
                    <button
                    onClick={onCancel}
                    className='btn btn-transparent text-decoration-underline f-s-14 text-blue'>Չեղարկել
                    </button>
                </Col>
                <Col xs={6} className='text-center'>
                    <button className='btn btn-blue' onClick={onSubmit}>Հաստատել</button>
                </Col>
            </Row>
        </div>
    );
}

export default CreateCallForm;
