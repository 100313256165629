import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { addLabSchemaToDoctorTemplate, addLabServiceToCard, 
    addLabServiceToDoctorTemplate, addLabServiceToSchema, 
    getOneMedCard} from "services/api";

import { resetLabCartIds, resetLabCartServices } from "features/labCartServices";
import { resetLabSchemaName, resetLabSchemaSlug } from "features/labSchemaName";
import { setHeaderTitle } from "features/headerTitle";

import FilterCard from "components/FilterCard";
import LabMaterialsAndTypes from "components/LabMaterialsAndTypes";

import diagnosTime from 'assets/Diagnos/diagnos-time.svg';
import timeDiagram from 'assets/Diagnos/time-diagram.svg';
import diagnosTimeBlue from 'assets/Diagnos/diagnos-time-blue.svg';
import timeDiagramBlue from 'assets/Diagnos//time-diagram-blue.svg';
import flask from 'assets/DoctorPage/flask.svg';
import flaskWhite from 'assets/DoctorPage/flaskWhite.svg';

const ChooseLabMaterialAndTypes = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {services} = useSelector(state => state.labCartServices);
    const { item } = useSelector(state => state.oneMedCard);
    const { fromDoctorProfile } = useSelector(state => state.fromDoctorProfile);
    const { name, slug } = useSelector(state => state.labSchemaName);
    const [ chooseFilter, setChooseFilter ] = useState('Ավելացնել հետ․');

    const [ chooseTabs, setChooseTabs ] = useState([
        {
          titleArm: 'Ավելացնել հետ․',
          icon: diagnosTimeBlue,
          active: true,
          choosenIcon: diagnosTime
        },
        {
          titleArm: 'Պահ․ հետ․',
          icon: timeDiagramBlue,
          active: false,
          choosenIcon: timeDiagram
        },
        {
          titleArm: 'Սխեմատիկ',
          icon: flask,
          active: false,
          choosenIcon: flaskWhite
        }
      ]);

    const handleCheckActiveChoose = (item) => {
        const newChooseData = chooseTabs.map((innerItem) => {
            if (innerItem.titleArm === item.titleArm) {
            innerItem.active = true
            } else {
            innerItem.active = false
            }
            return innerItem
        });
        setChooseFilter(_ => item.titleArm);
        setChooseTabs(_ => {
            return [
            ...newChooseData
            ]
        });
    };  

    const handleAddButton = () => {
        let ids  = [];

        services && services.forEach(element => {
            ids.push(element.id);
        });

        let params = {
                'serviceIds' : ids,
            };


       if(name !== "") {
            params = {
                'serviceIds' : ids,
                'schema' : name
            };
            addLabSchemaToDoctorTemplate(params)
                .then(() => {
                    dispatch(resetLabCartServices([]));
                    dispatch(resetLabCartIds([]));

                    dispatch(resetLabSchemaName());
                    navigate('/profile/myTemplates');
                })
        } else if (slug) {
            params = {
                'serviceIds' : ids,
                'schemaSlug' : slug
            };
            addLabServiceToSchema(params)
            .then(() => {
                dispatch(resetLabCartServices([]));
                dispatch(resetLabCartIds([]));

                dispatch(resetLabSchemaSlug());

                navigate('/profile/myTemplates');
            })
        } else if (fromDoctorProfile) {
            addLabServiceToDoctorTemplate(params)
                .then(() => {
                    dispatch(resetLabCartServices([]));
                    dispatch(resetLabCartIds([]));
                    navigate('/profile/myTemplates');
                })
                .catch(e => {
                    console.log(e);
                });

        } else {
            params = {
                'serviceIds' : ids,
                'medicalCardCode' : item.code 
            };

            addLabServiceToCard(params)
                .then((response) => {
                    dispatch(resetLabCartServices([]));
                    dispatch(resetLabCartIds([]));
                    getOneMedCard(dispatch, item.code)
                        .then(res => {
                            navigate('/oneMedCard');
                        })
                    
                })
                .catch(e => {
                    console.log(e);
                });
            }
    };

    useEffect(() => {
        dispatch(setHeaderTitle('Ընտրել հետազոտ․'));
    }, []);  

    return (
        <div className="mt-100">
             <div className='one-med-card-item'>
                <div className='diagnos-card-block'>
                {
                    chooseTabs.length && chooseTabs.map((item, index) => {
                    return <FilterCard
                        key={`${item.text}_${index}`}
                        item={item}
                        checkActive={handleCheckActiveChoose}
                        active={item.active}
                    />
                    })
                }
                </div>
            </div>


            {chooseFilter === 'Ավելացնել հետ․'  &&
                <LabMaterialsAndTypes withSaveButton onClickBtn={() => handleAddButton()} />
            }
        </div>
       
    )
}

export default ChooseLabMaterialAndTypes;