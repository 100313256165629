import React, {useEffect, useState} from 'react';

import { getCategories, getPharmsByCategories, getWorkplacesWithCategories } from 'services/api';

import WorkplaceCategory from 'components/WorkplaceCategory';
import WorkplaceCard from 'components/WorkplaceCard';

import './component.css';

const CatsWithWorkplaces = ({type = 'workplace'}) => {
    const [ categories, setCategories ] = useState([]);

    useEffect(() => {
        if (!categories.length) {
          if (type === 'workplace') {
            getWorkplacesWithCategories()
              .then(res => {
                setCategories(res);
              })
              .catch(e => {
                console.log(e);
              })
          } else if (type === 'lab') {
            getCategories()
              .then(res => {
                setCategories(res);
              })
              .catch(e => {
                console.log(e);
              })
          } else if (type === 'pharm') {
             getPharmsByCategories()
                  .then(res => {
                    setCategories(res);
                  })
                  .catch(e => {
                    console.log(e);
                  });
          }
         
        }
        return () => {
          setCategories([]);
        }
      }, []);

    return (
        <div className='row mt-5'>
            {
              categories && categories.length > 0 && categories.map((category, index) => {
                return <React.Fragment key={`${category.titleArm}_${index}`}>
                  <WorkplaceCategory 
                    category={category} 
                    link={`/${type}/${category.slug}`}
                  />
                  <div className='category-horizontal'>
                    {
                      category.workplaces && category.workplaces.length && category.workplaces.map((workplace, index) => {
                        return (
                          <WorkplaceCard 
                            key={`${workplace.titleArm}_${index}`}
                            workplace={workplace}
                            link={`/${type}/one/${workplace.slug}`}
                          />
                          )
                        })
                    } 
                  </div>
                </React.Fragment> 
                  
              })
            }
        </div>
    )
}

export default CatsWithWorkplaces;