import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getCookie } from 'utils';
import { setApplicationTypeSlice } from 'features/applicationType';
import { getDoctorProfileCalls, getPatientDiagnosis, getPatientLabServices, getPatientMedCards, getUsersDetails } from 'services/api';

import SideSettings from 'components/SideSettings';

import request from 'assets/DoctorPage/request.svg';
import PatientExample from "assets/patientExample.png";
import flask from 'assets/DoctorPage/flask.svg';
import corona from 'assets/DoctorPage/corona-virus.svg';
import statistics from 'assets/DoctorPage/statistsics.svg';


const BaseDomain = process.env.REACT_APP_BASE_DOMAIN;

const PatientAccount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { item } = useSelector(state => state.userDetails);

  useEffect(() => {
    const checkAccess = getCookie('access_token');
    if (!checkAccess) {
      navigate('/login');
    }

    if (Object.keys(item).length === 0) {
      getUsersDetails(dispatch)
    }
  }, []);

  const handleGetPatientService = (type) => {
    dispatch(setApplicationTypeSlice(type));
    switch (type) {
      case 'request':
        getDoctorProfileCalls(dispatch, true)
          .then(() => {
            navigate('/applications');
          })
          .catch(e => {
            console.log(e);
          });
        break;
      case 'cards':
        getPatientMedCards(dispatch)
          .then(() => {
            navigate('/medicalCards');
          })
          .catch(e => {
            console.log(e);
          });
        break;
      case 'labServices':
        getPatientLabServices(dispatch, item.slug)
          .then(() => {
            navigate('/profile/onePatient/labServices');
          })
          .catch(e => {
            console.log(e);
          });
        break;
      case 'diagnosis':
        getPatientDiagnosis(dispatch, item.slug)
          .then(() => {
            navigate('/profile/onePatient/diagnosis');
          })
          .catch(e => {
            console.log(e);
          });
        break;

      default: break;
    }

  };

  return (
    <div className='doctor-account-block'>
      <SideSettings />
      <div className='doctor-info-block'>
        <div className='doctor-info-image-block'>
          {
            item && item.image ?
              <img src={BaseDomain + item.image} alt='doctor' className='doctor-info-image' />
              : <img className="doctorPatientImg d-block" src={PatientExample} alt={item.titleArm} />

          }
        </div>

        <div className='text-block'>
          <p className='body2-300-title text-center'>
            {item && item.name ? item.name : ''}
          </p>
        </div>
      </div>

      <div className='doctor-info-service-block'>
        <div
          className='doctor-info-service-item'
          onClick={_ => handleGetPatientService('request')}
        >
          <div className='doctor-info-service-item-icon-block'>
            <img src={request} alt='request' />
          </div>

          <div className='text-block'>
            <p className='body2-300-title text-center'>ԲԿ-ի հայտեր</p>
          </div>
        </div>
        <div
          className='doctor-info-service-item'
          onClick={_ => handleGetPatientService('cards')}
        >
          <div className='doctor-info-service-item-icon-block'>
            <img src={request} alt='request' />
          </div>

          <div className='text-block'>
            <p className='body2-300-title text-center'>Բժշկ․ Քարտ</p>
          </div>
        </div>
        <div className='doctor-info-service-item' onClick={_ => handleGetPatientService('indicatorData')}>
          <div className='doctor-info-service-item-icon-block'>
            <img src={statistics} alt='pdf' />
          </div>

          <div className='text-block'>
            <p className='body2-300-title text-center'>Ցուցանիշներ</p>
          </div>
        </div>
        <div className='doctor-info-service-item' onClick={_ => handleGetPatientService('labServices')}>
          <div className='doctor-info-service-item-icon-block'>
            <img src={flask} alt='flask' />
          </div>

          <div className='text-block'>
            <p className='body2-300-title text-center'>Հետազոտութ.</p>
          </div>
        </div>
      </div>

      <div className='doctor-info-service-item' onClick={_ => handleGetPatientService('diagnosis')}>
        <div className='doctor-info-service-item-icon-block'>
          <img src={corona} alt='corona' />
        </div>

        <div className='text-block'>
          <p className='body2-300-title text-center'>Ախտորոշում</p>
        </div>
      </div>
    </div>
  )
}

export default PatientAccount