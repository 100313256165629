import React from 'react';
import {Col, Row} from "react-bootstrap";
import Bookmark from "../../assets/DoctorPage/Rectangle1.png";
import {Link} from "react-router-dom";

const DrugInfo = ({drug, withMr = false}) => {
    const BaseDomain = `${process.env.REACT_APP_BASE_DOMAIN}`;

    return (
        <Col className={`oneDoctorDiv ${withMr && 'mr16'}`}>
            <Link to={`/doctor/one/${drug.slug}`}>
                <Row>
                    <Col className="text-center position-relative">
                        <div className="doctorImg">
                            <img src={BaseDomain + drug.imagePath} alt={drug.titleArm}/>
                        </div>
                        <div className="bookmark">
                            <img src={Bookmark} alt="bookmark"/>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h3 className="doctorName">
                            {drug.titleArm}
                        </h3>
                        <p className="servicePrice">
                            {drug.price}
                        </p>
                    </Col>
                </Row>
            </Link>
        </Col>
    );
}

export default DrugInfo;