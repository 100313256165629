import React from 'react';
// import Header from 'sections/Header';
import Banner from 'components/Banner';
import DrugCategories from 'components/DrugCategories';
import CatsWithWorkplaces from 'components/CatsWithWorkplaces';


const Pharms = (props) => {
    return (
        <div>
            <Banner />
            <DrugCategories link={true} title={`Ընտրել խմբավորումը`}/>
            <CatsWithWorkplaces type="pharm"/>
        </div>
    );
}

export default Pharms;