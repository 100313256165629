import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { hasOwnerProperty } from 'utils';

import './component.css';

const DiagnosListCard = ({
  ids,
  item,
  number,
  checkCall,
  withShareActions = false
}) => {
  const [ active, setActive ] = useState(false);

  const handleAction = () => {
    if (hasOwnerProperty(item, 'lastLevel')) {
      if (item.lastLevel === false) {
        checkCall('children', item.id, item.slug);
      } else {
        checkCall('single', item.id, item.slug);
        setActive(_ => !active);
      } 
    }
  }

  useEffect(() => {
    if (ids.includes(item.id)) {
      setActive(_ => true);
    }
  }, [ids]);

  return (
    <div
      onClick={handleAction}
      className='diagnos-list-card'
      style={{
        backgroundColor: active ? 'var(--colors-1-1)' : 'var(--white)'
      }}
    >
      <div className='diagnos-list-card-roman-block'>
        <span
          className='caption1-300 diagnos-list-card-roman-number'
          style={{
            color: active ? 'var(--white)' : 'var(--colors-1-1)'
          }}
        >{ number }</span>
      </div>

      <div className='diagnos-list-card-info-block'>
        <h3
          className='caption1-200 diagnos-list-card-info-title'
          style={{
            color: active ? 'var(--white)' : 'var(--colors-4-2)'
          }}
        >{ item.titleArm }</h3>

        <span
          className='caption1-200'
          style={{
            color: active ? 'var(--white)' : 'var(--colors-2-1)'
          }}
        >{ item.code }</span>
      </div>
      {withShareActions &&  
          <div shareDiagnosis>
              
          </div>
      }
    </div>
  )
};

DiagnosListCard.propTypes = {
  ids: PropTypes.array,
  item: PropTypes.object,
  number: PropTypes.string,
  checkCall: PropTypes.func
};

export default DiagnosListCard
